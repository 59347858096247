module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PH633MC","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Realmar","short_name":"realmar","start_url":"/","background_color":"#ABE5FE","theme_color":"#00176B","display":"minimal-ui","lang":"it","icon":"/Users/marcostocco/Sites/realmar/src/images/favicon_512.png","icons":[{"src":"favicons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicons/icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"768f6d9a894cb7d30990886c0b92b8c1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Montserrat:400,400i,700,700i","Source Sans Pro:400,400i,700,700i"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["it"],"defaultLanguage":"it","siteUrl":"https://www.realmar.it","i18nextOptions":{"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"Piatti di Pesce Pronti in Pochi Minuti","titleTemplate":"%s | Realmar","url":"https://www.realmar.it","language":"it","description":"Porta il gusto del mare sulla tua tavola con i piatti pronti in pochi minuti, realizzati con le migliori materie prime. Acquista ora!","noindex":true,"openGraph":{"type":"website","title":"Piatti di Pesce Pronti in Pochi Minuti","description":"Porta il gusto del mare sulla tua tavola con i piatti pronti in pochi minuti, realizzati con le migliori materie prime. Acquista ora!","locale":"it_IT","site_name":"Realmar"}},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
